import React from "react";
import { graphql } from "gatsby";
// @ts-ignore
import Vimeo from "@u-wave/react-vimeo";
import Slider from "react-slick";
import { styled, media, white, pink, fonts } from "../styles";
import { Content } from "../components/Content";
import { Link } from "../components/Link";
import { Image } from "../components/Image";
import { List, ListItem } from "../components/List";
import { ShadowedBox } from "../components/ShadowedBox";
import { Page } from "../components/Page";
import { SpecialInterface } from "../views/SpecialsPage/SpecialInterface";
import NextSpecial from "../views/SpecialsPage/assets/NextSpecial_ani.inline.svg";

export interface SpecialPageProps {
  data: {
    special: SpecialInterface;
  };
  pageContext: {
    lang: "de" | "en";
    next: string;
  };
}

export const SpecialTemplate: React.FC<SpecialPageProps> = ({
  data: { special },
  pageContext: { lang, next },
  ...props
}) => {
  return (
    <Page
      title="Special"
      seoTitle={special.name}
      keywords={[
        "German Wahnsinn",
        "Cases",
        "Arbeiten",
        "dynamische Musik",
        "360° Audio",
        "Virtual Reality",
        "Augmented Reality",
        "VR",
        "AR",
        "Chatbots",
        "Conversational Interfaces",
        "Peta"
      ]}
      description="Unser latest shit: Cases zu dynamischer Musik, 360° Audio, Virtual Reality, Augmented Reality, Chatbots & Conversational Interfaces."
      isModal={true}
      backLink={`/specials/#${special.slug}`}
      sideLabel={false}
      mobileHeaderFixed={true}
      {...props}
    >
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />

      <Content>
        <SpecialHeading1>{special.name}</SpecialHeading1>

        <SpecialHeaderContainer>
          <SpecialHeaderImage
            fluid={special.header.url.image.fluid}
            loading="eager"
            fadeIn={false}
          />

          <SpecialHeaderContent>
            <SpecialHeaderInnerContent>
              <SpecialHeading2>{special.name}</SpecialHeading2>

              <SpecialTags inline>
                {special.tags.map(tag => (
                  <ListItem key={tag}>{tag}</ListItem>
                ))}
              </SpecialTags>

              <SpecialIntro1
                dangerouslySetInnerHTML={{ __html: special.intro[lang] }}
              />
            </SpecialHeaderInnerContent>
          </SpecialHeaderContent>
        </SpecialHeaderContainer>

        <SpecialIntro2
          dangerouslySetInnerHTML={{ __html: special.intro[lang] }}
        />

        <SpecialCopy
          dangerouslySetInnerHTML={{ __html: special.text1[lang] }}
        />

        <SpecialVideoContainer>
          <SpecialVideo video={special.video.id} />
        </SpecialVideoContainer>

        <SpecialCopy
          dangerouslySetInnerHTML={{ __html: special.text2[lang] }}
        />

        <SpecialSlider
          dots={true}
          infinite={true}
          speed={1000}
          accessibility={true}
          adaptiveHeight={false}
          arrows={true}
          autoplay={true}
          pauseOnFocus={true}
          pauseOnHover={true}
          swipeToSlide={true}
          swipe={true}
          transition="easeOut"
        >
          {special.slider.items
            .filter(item => !!item.url)
            .map(item =>
              item.url && item.url.image && item.url.image.fluid ? (
                <div key={item.url.image.fluid.src}>
                  <Image fluid={item.url.image.fluid} />
                </div>
              ) : null
            )}
        </SpecialSlider>

        <SpecialFooterContainer>
          <SpecialFooterTextContainer>
            <SpecialFooterText
              dangerouslySetInnerHTML={{ __html: special.text3[lang] }}
            />

            {next && (
              <SpecialFooterNextLinkContainer>
                <Link to={`/specials/${next}/`}>
                  <NextSpecial />
                </Link>
              </SpecialFooterNextLinkContainer>
            )}
          </SpecialFooterTextContainer>

          {special.footer.url && special.footer.url.image && (
            <SpecialFooterImage
              src={
                special.footer.url &&
                special.footer.url.image &&
                special.footer.url.image.fluid
                  ? special.footer.url.image.fluid.src
                  : undefined
              }
            />
          )}
        </SpecialFooterContainer>
      </Content>
    </Page>
  );
};

const SpecialHeaderContainer = styled(ShadowedBox)`
  max-width: 1152px;
  margin: 0 -40px 5rem -40px;

  ${media.tablet} {
    margin: 135px -40px 7rem -40px;
  }

  ${media.landscape} {
    margin: 40px -40px 5rem -40px;
  }

  ${media.laptop} {
    width: 100%;
    margin: 135px auto 9rem auto;
  }

  ${media.desktop} {
    width: 90%;
  }

  ${media.hd} {
    width: auto;
  }
`;

const SpecialHeaderImage = styled(Image)`
  max-width: none;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: auto;
  vertical-align: middle;
  margin-left: -100%;
  width: 200%;

  ${media.landscape} {
    margin-left: -100%;
  }

  ${media.tablet} {
    width: 150%;
    margin-left: -50%;
  }

  ${media.laptop} {
    width: 100%;
    margin-left: 0;
  }
`;

const SpecialHeaderContent = styled.div<{ right?: boolean }>`
  display: flex;

  color: ${white};
  text-align: ${({ right }) => (right ? "right" : "left")};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;

  ${media.laptop} {
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const SpecialHeaderInnerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  width: 100%;
  height: 100%;
  padding: 2rem;

  ${media.tablet} {
    width: 60%;
    padding: 3rem;
    justify-content: space-between;
  }

  ${media.landscape} {
    padding: 3rem;
    justify-content: space-between;
    justify-content: flex-end;
  }

  ${media.laptop} {
    width: 66.667%;
    margin-left: 0;
    padding-left: 7rem;
  }
`;

const SpecialIntro1 = styled.p`
  display: none;
  color: inherit;

  ${media.landscape} {
    display: none;
  }

  ${media.tablet} {
    display: block;
    margin-top: auto;
    font-size: 22px;
  }

  ${media.laptop} {
    width: 100%;
  }
`;

const SpecialIntro2 = styled.p`
  width: 100%;
  margin-bottom: 3rem;
  display: block;

  ${media.tablet} {
    display: none;
  }

  ${media.landscape} {
    display: block;
  }
`;

const SpecialCopy = styled.p`
  width: 100%;
  margin-bottom: 5rem;

  ${media.tablet} {
    width: 94%;
    margin-right: 3%;
    margin-left: 3%;
    margin-bottom: 5rem;
  }

  ${media.landscape} {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  ${media.laptop} {
    width: 84%;
    margin-left: 7rem;
    margin-bottom: 7rem;
  }
`;

const SpecialHeading1 = styled.h2`
  overflow: visible;
  font-size: 27px;
  margin-top: 135px;
  display: block;

  ${media.tablet} {
    display: none;
  }
`;

const SpecialHeading2 = styled.h2`
  overflow: visible;
  font-size: 27px;
  display: none;

  ${media.tablet} {
    font-size: 50px;
    display: block;
  }

  ${media.laptop} {
    font-size: 60px;
  }
`;

const SpecialTags = styled(List)`
  font-size: 15px;
  margin-bottom: 0;

  ${media.tablet} {
    font-size: 16px;
    margin-bottom: auto;
  }

  ${media.laptop} {
    font-size: 17px;
    margin-bottom: 3rem;
  }

  ${media.landscape} {
    margin-bottom: unset;
  }

  ${ListItem} {
    font-weight: 700;
    margin-right: 0.5rem;
    font-size: inherit;
  }
`;

const SpecialVideoContainer = styled.div`
  margin: 0 -40px 3rem -40px;

  ${media.tablet} {
    margin: 0 -40px 5rem -40px;
  }

  ${media.laptop} {
    margin: 0 0 7rem 0;
  }
`;

const SpecialVideo = styled(Vimeo)`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
  margin: 0 0 3rem 0;

  ${media.tablet} {
    margin: 0 0 5rem 0;
  }

  ${media.laptop} {
    margin: 0 5% 7rem 5%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const SpecialSlider = styled(Slider)`
  margin: 0 -40px 3rem -40px;

  ${media.tablet} {
    margin: 0 0 5rem 0;
  }

  ${media.laptop} {
    margin: 0 5% 7rem 5%;
  }

  .slick-dots {
    text-align: right;
    padding-right: 1rem;

    li {
      margin: 0;
    }

    button {
      &:hover:before {
        color: ${pink} !important;
        animation-name: wahnsinn;
        animation-duration: 0.3s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }

      &:before {
        font-family: ${fonts.vario};
        font-size: 1rem;
        opacity: 1;

        content: "\\\\";
      }
    }

    .slick-active {
      button {
        &:before {
          opacity: 1;
          content: "•";
        }
      }
    }
  }

  .slick-arrow {
    width: 2rem;

    &:hover:before {
      color: ${pink} !important;
      animation-name: wahnsinn;
      animation-duration: 0.3s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    &:before {
      font-family: ${fonts.vario};
      font-size: 2rem;
      color: #000;
    }
  }

  .slick-arrow {
    display: none !important;
    height: 3rem;
  }

  ${media.tablet} {
    .slick-arrow {
      display: inherit !important;
    }
  }
  .slick-prev {
    left: -35px;

    &:before {
      content: "≤";
      opacity: 1;
    }
  }

  .slick-next {
    right: -35px;

    &:before {
      content: "≥";
      opacity: 1;
    }
  }
`;

const SpecialFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SpecialFooterTextContainer = styled.div`
  width: 100%;

  ${media.tablet} {
    width: 94%;
    margin-right: 3%;
    margin-left: 3%;
  }

  ${media.laptop} {
    width: 45%;
    min-height: 40rem;
    margin-left: 7rem;
    margin-right: 0;
  }
`;

const SpecialFooterText = styled.p``;

const SpecialFooterNextLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 3rem auto 0 auto;
  width: 50%;

  ${media.tablet} {
    width: 100%;
  }

  svg {
    max-width: 200px;
    width: 100%;

    &:hover {
      color: ${pink};
      fill: ${pink};
    }
  }
`;

const SpecialFooterImage = styled(Image)`
  display: none;

  ${media.laptop} {
    display: block;
    width: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    margin-right: -40px;
    margin-bottom: -8rem;
  }
`;

export const pageQuery = graphql`
  query specialQuery($slug: String!) {
    special: specialsJson(slug: { eq: $slug }) {
      id
      name
      slug
      tags
      header {
        alt
        title
        url {
          image: childImageSharp {
            fluid(maxWidth: 1100, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      intro {
        de
        en
      }
      text1 {
        de
        en
      }
      video {
        id
        rights
      }
      text2 {
        de
        en
      }
      slider {
        items {
          type
          url {
            image: childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      text3 {
        de
        en
      }
      footer {
        alt
        title
        url {
          image: childImageSharp {
            fluid(maxWidth: 640, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default SpecialTemplate;
